.photo
    width: 100%
    height: auto
    min-height: 500px

    &__loading-spinner
        text-align: center
        padding: 20px
        font-size: 1.5em

        img
            width: 50px
            height: 50px
            animation: spin 2s linear infinite

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
