.image-grid
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
    gap: 10px
    padding: 20px

    @media (max-width: 400px)
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))

    &__item
        width: 100%
        padding-top: 100%
        background-size: cover
        background-position: center
        cursor: pointer
