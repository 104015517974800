@import '../index.sass'

.hero-nav
    display: flex
    justify-content: center

    &__buttons
        margin-top: 2rem
        display: flex
        justify-content: space-around
        gap: 20rem
        width: 100%

        @media only screen and (max-width: 940px)
            gap: 2rem

    &__button
        @extend %button-style
        animation: heroNavFadeIn 1s forwards
        animation-delay: 1.3s
        opacity: 0
        min-width: 200px

        @media only screen and (max-width: 750px)
            min-width: 150px

        @media only screen and (max-width: 400px)
            min-width: 120px

        &--2
            animation-delay: 1.6s

    &__logo
        animation: heroNavFadeIn 2s forwards
        animation-delay: 1s
        opacity: 0
        width: 250px

        &-container
            display: flex
            justify-content: center
            width: 100%

    &__row
        display: flex
        justify-content: center

@keyframes heroNavFadeIn
    from
        opacity: 0
    to
        opacity: 1
