.image-modal
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.8)
    display: flex
    justify-content: center
    align-items: center
    z-index: 100
    opacity: 0
    transition: opacity 0.3s ease-in-out

    img
        width: 95%
        height: 95%
        object-fit: contain

    &__close
        position: absolute
        top: 10px
        right: 10px
        background: none
        border: none
        color: white
        font-size: 32px
        cursor: pointer
        padding: 10px
