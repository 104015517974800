.hero-parallax
    position: relative
    background-color: black
    width: 100vw
    height: 100vh
    overflow: hidden

    &__layer
        position: absolute
        width: 100%
        height: 100%
        overflow: hidden

        img
            width: 100%
            height: 100%
            object-fit: cover
            object-position: center center
            transform-origin: bottom center
            transition: transform 10s ease-out, object-position 15s ease-out
            animation: moveDown 15s forwards

            &.zoom-middle
                transform: scale(1.075)

            &.zoom-front
                transform: scale(1.275)

            &.move-down
                animation: moveDown 15s forwards

    &__layer--back
        z-index: 1

    &__layer--middle
        z-index: 2

    &__layer--front
        z-index: 3

    &__content
        position: absolute
        width: 100%
        height: 100%
        z-index: 4

    &__nav-container
        padding: 4rem
        width: 100%

        @media only screen and (max-width: 940px)
            padding: 2rem 1rem

    &__scroll-arrow
        position: absolute
        bottom: 20px
        left: 50%
        transform: translateX(50%)
        color: white
        animation: SlowBounce 4s infinite
        transition: opacity 2s ease
        width: 60px
        height: 60px

        &--hide
            opacity: 0

    svg
        opacity: 0
        animation: heroFadeIn 4s forwards
        animation-delay: 4s
        width: 100%
        height: 100%

@keyframes heroFadeIn
    from
        opacity: 0
    to
        opacity: .8

@keyframes SlowBounce
    0%, 20%, 50%, 80%, 100%
        transform: translateY(0) translateX(-50%)
    40%
        transform: translateY(-20px) translateX(-50%)
    60%
        transform: translateY(-10px) translateX(-50%)

@keyframes moveDown
    0%
        object-position: 50% 30% // 20% above center vertically
    100%
        object-position: 50% 70% // 20% below center vertically