.app
    display: flex
    flex-direction: column
    min-height: 100vh

    &.home-page
        .nav-container
            display: none

.nav-container
    position: fixed
    flex-shrink: 0
    width: 100%
    z-index: 99
    top: 0
