@import '../index.sass'

.home
  position: relative
  width: 100%
  height: 100%

  &__button
    @extend %button-style

    &-container
      margin-top: 3rem

  &__col
    margin: 4rem 0
    text-align: right

  &__row
    justify-content: flex-end
