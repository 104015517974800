%button-style
    padding: 10px 20px
    border: 1px solid white
    border-radius: 5px
    background-color: rgba(255, 255, 255, 0.2)
    transition: background-color 0.3s, color 0.3s
    color: white
    text-align: center
    text-decoration: none
    font-size: 20px

    &:focus-visible,
    &:hover
        cursor: pointer
        background-color: rgba(255, 255, 255, 0.7)
        color: black