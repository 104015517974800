.footer
  box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.2)
  padding: 20px
  font-size: 0.9rem

  &__col
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    margin: 2rem 0
    text-align: right
    width: 100%

  &__hour
    margin: 0

  img
    width: 100px

  a
    color: inherit
    text-decoration: none
